<template>
	<div class="container">
		<h1>User</h1>
	</div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
	name: 'UserView',
	setup() {
		//
	}
})
</script>

<style lang="scss" scoped>

</style>